import React, { useState, useEffect } from 'react';
import he from 'he';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { HiOutlineNewspaper } from 'react-icons/hi';
import '../assets/scss/modal.scss';
import axios from 'axios';

const formatDate = (dateStr) => {
    if (!dateStr) return 'Ukjent dato';

    let year, month, day;
    if (dateStr.includes('-')) {
        [year, month, day] = dateStr.split('-').map(Number);
    } else if (dateStr.length === 8) {
        year = parseInt(dateStr.substring(0, 4), 10);
        month = parseInt(dateStr.substring(4, 6), 10);
        day = parseInt(dateStr.substring(6, 8), 10);
    } else {
        return 'Ukjent dato';
    }

    return `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year}`;
};

const PostModal = ({ post, onClose }) => {
    const [pdfUrl, setPdfUrl] = useState(null);
    const landingssideInfo = post?.acf?.landingsside_info;

    useEffect(() => {
        const fetchPdfUrl = async () => {
            if (landingssideInfo?.pdf) {
                if (typeof landingssideInfo.pdf === 'number') {
                    try {
                        const response = await axios.get(
                            `${process.env.REACT_APP_API_URL}/wp-json/wp/v2/media/${landingssideInfo.pdf}`
                        );
                        setPdfUrl(response.data.source_url);
                    } catch (error) {
                        console.error('Error fetching PDF URL:', error);
                        setPdfUrl(null);
                    }
                } else {
                    setPdfUrl(landingssideInfo.pdf);
                }
            }
        };

        fetchPdfUrl();
    }, [landingssideInfo?.pdf]);

    if (!post) return null;

    // Extract additional dates from the repeater field "gjentagende_aktivitet"
    const extraDates = post.acf?.gjentagende_aktivitet?.map(entry => formatDate(entry.velg_dato)).filter(date => date !== 'Ukjent dato');

    return (
        <div className="modal">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>Lukk</button>

                <h2>{he.decode(post.title?.rendered || '')}</h2>

                <p><strong>Dato:</strong> {post.acf?.dato ? formatDate(post.acf.dato) : 'Ukjent dato'}</p>

                {post.acf?.omrade && (
                    <p><strong>Område:</strong> {post.acf.omrade}</p>
                )}

                {/* New section: "Andre datoer" (only displayed if there are extra dates) */}
                {extraDates?.length > 0 && (
                    <div>
                        <p><strong>Andre datoer:</strong></p>
                        <ul>
                            {extraDates.map((date, index) => (
                                <li key={index}>{date}</li>
                            ))}
                        </ul>
                    </div>
                )}

                {landingssideInfo ? (
                    <div>
                        <p><strong>Beskrivelse:</strong> {landingssideInfo.beskrivelse_av_aktivitet}</p>

                        <div className="dates-grid">
                            <div>
                                <p><strong>Frist for bestilling:</strong> {landingssideInfo.frist_for_bestilling}</p>
                                <p><strong>Frist for materiell:</strong> {landingssideInfo.frist_for_materiell}</p>
                            </div>
                            <div>
                                <p><strong>Salgsperiode fra:</strong> {formatDate(landingssideInfo.salgsperiode_fra)}</p>
                                <p><strong>Salgsperiode til:</strong> {formatDate(landingssideInfo.salgsperiode_til)}</p>
                            </div>
                        </div>

                        <div className="links-row">
                            {pdfUrl && (
                                <a href={pdfUrl} target="_blank" rel="noopener noreferrer" className="link">
                                    <AiOutlineFilePdf className="icon" /> Last ned PDF
                                </a>
                            )}
                            {landingssideInfo.lenke_til_salgsbrev && (
                                <a href={landingssideInfo.lenke_til_salgsbrev} target="_blank" rel="noopener noreferrer" className="link">
                                    <HiOutlineNewspaper className="icon" /> Les salgsbrev
                                </a>
                            )}
                        </div>
                    </div>
                ) : (
                    <p className="no-info">Ingen ekstra informasjon tilgjengelig for denne posten.</p>
                )}
            </div>
        </div>
    );
};

export default PostModal;
